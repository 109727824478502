<template>
    <BaseButton
        v-if="url"
        :element="isExternal ? 'a' : NuxtLink"
        v-bind="linkAttrs"
        :class="
            appearance === 'link' ? 'base-button--link' : ''
        "
    >
        <slot name="default">{{ link?.text }}</slot>
    </BaseButton>
</template>

<script setup>
import { HOME_URI } from '~/enums/page-uri';
const NuxtLink = resolveComponent('NuxtLink');

const localePath = useLocalePath();
const { locale } = useI18n();

const props = defineProps({
    link: {
        type: [Array, Object, String],
        required: true,
        default: () => {}
    },
    appearance: {
        type: String,
        default: 'link',
        validator: (value) => ['link', 'button'].includes(value)
    }
});
const { link } = toRefs(props);

const checkExternal = (uri) => (
    uri.startsWith('http') ||
    uri.startsWith('mailto') ||
    uri.startsWith('tel')
);

/**
 * Given a variety of link types, return the URL to link to. Translate it, and replace any __home__ placeholders
 */
const url = computed(() => {
    let linkValue = link.value;
    if (!linkValue) {
        return null;
    }

    if(Array.isArray(linkValue)) {
        linkValue = linkValue[0];
    }

    let uri = null;

    if (linkValue?.element?.uri) {
        uri = linkValue.element.uri;
    } else if (linkValue?.url) {
        // URL should be absolute
        uri = linkValue.url;
    } else if (linkValue?.uri) {
        // URI should be relative
        uri = linkValue?.uri;
    } else if (typeof linkValue === 'string') {
        // Directly passed string
        uri = linkValue;
    }

    if (!uri) {
        return null;
    }

    if (uri === HOME_URI) {
        return '/';
    }

    // External links
    if(checkExternal(uri)) {
        return uri;
    }

    // Internal links
    return uri.startsWith('/') ? uri : `/${uri}`;
});

const isExternal = computed(() => (
    url?.value &&
    typeof url?.value === 'string' &&
    checkExternal(url?.value)
));

const linkAttrs = computed(() => {
    const baseAttrs = {
        target: toValue(link)?.target,
        'aria-label': toValue(link)?.ariaLabel
    };

    // Hack to fix a weird issue in Nuxt-I18N version 8
    if (locale.value === 'en' && url.value.startsWith('/blog')) {
        return {
            to: `/en${url.value}`
        };
    }

    return isExternal.value ? {
        ...baseAttrs,
        href: url.value,
    } : {
        ...baseAttrs,
        to: localePath(url.value, locale.value)
    };
});
</script>
